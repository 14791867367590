.ant-modal-confirm .ant-modal-confirm-body .ant-modal-confirm-title + .ant-modal-confirm-content {
  max-width: 100%;
}

.anticon {
  display: inline-flex !important;
}

.ant-touchpoints-slider.ant-carousel .slick-list {
  padding-top: 1rem;
  padding-bottom: 4rem;
}

.ant-touchpoints-slider.ant-carousel .slick-dots li button {
  background: #a0a0a0 !important;
}

.ant-touchpoints-slider.ant-carousel .slick-dots li.slick-active button {
  background: #ed5a23 !important;
}

.ant-space-items-center > .ant-space-item {
  align-items: center;
  display: flex;
}

.ant-space-50-50,
.ant-space-65-35 {
  width: 100%;
}

.ant-space-50-50 > .ant-space-item {
  flex: 1;
}

.ant-space-65-35 > .ant-space-item:first-child {
  flex: 1.3;
}

.ant-space-65-35 > .ant-space-item:last-child {
  flex: 0.7;
}

.ant-w-full-upload,
.ant-w-full-upload .ant-upload.ant-upload-select {
  width: 100% !important;
  height: 12rem !important;
}

.ant-small-upload,
.ant-small-upload .ant-upload.ant-upload-select {
  width: 6rem !important;
  height: 6rem !important;
}

.ant-figma-upload,
.ant-figma-upload .ant-upload.ant-upload-select {
  width: 100% !important;
  height: 100% !important;
  border-radius: 0 !important;
  background-color: transparent !important;
  border: none !important;
}

.ant-figma-upload span.ant-upload:focus-visible {
  --tw-ring-opacity: 0.5;
  --tw-ring-color: rgb(113 174 234 / var(--tw-ring-opacity));
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width)
    var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width))
    var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.ant-small-upload-active .ant-upload.ant-upload-select {
  border: 2px solid #f0591f !important;
}

.ant-switch {
  /* override tailwind's reset bg color */
  background: rgba(0, 0, 0, 0.25);
}

.ant-change-style-radio-group .ant-radio-wrapper {
  border: 1px solid #d3d3d3;
  border-radius: 0.4rem;
  overflow: hidden;
  margin-inline-end: 0;
}

.ant-change-style-radio-group .ant-radio-wrapper:hover {
  border: 1px solid #1677ff;
}

.ant-change-style-radio-group .ant-radio-wrapper-checked {
  border: 1px solid #1677ff !important;
}

.ant-change-style-radio-group .ant-radio-wrapper span.ant-radio + * {
  padding-left: 0;
  padding-right: 0;
}

.ant-change-style-radio-group .ant-radio-wrapper::after {
  display: none;
}

.ant-change-style-radio-group .ant-radio {
  position: absolute;
  top: 0.1rem;
  right: 0.1rem;
  z-index: 2;
}

.ant-upload.ant-upload-select,
.ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload.ant-upload-select,
.ant-upload-wrapper.ant-upload-picture-circle-wrapper .ant-upload.ant-upload-select {
  margin: 0;
}

.ant-upload-medium .ant-upload.ant-upload-select,
.ant-upload-medium.ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload.ant-upload-select,
.ant-upload-medium.ant-upload-wrapper.ant-upload-picture-circle-wrapper
  .ant-upload.ant-upload-select {
  width: 122px;
  height: 122px;
}

.ant-float-btn-zoom .ant-float-btn-description {
  font-size: 1rem !important;
  font-weight: 600;
}

.ant-paragraph-form-label {
  font-size: 1.4rem;
  padding-left: 2;
  margin-top: 2rem;
  margin-bottom: 1rem;
}

.ant-picker-dropdown .ant-btn-primary {
  background: #1677ff;
}

.ant-large-modal.ant-modal {
  width: auto !important;
  top: 2rem;
  max-width: 80rem !important;
}

.ant-fullscreen-modal-on-mobile.ant-modal .ant-modal-close {
  top: 2.3rem !important;
}

@media (max-width: 768px) {
  .ant-fullscreen-modal-on-mobile.ant-modal {
    width: 100% !important;
    max-width: 100% !important;
    height: 100% !important;
    min-height: 100% !important;
    top: 0 !important;
    padding: 0;
  }

  .ant-fullscreen-modal-on-mobile.ant-modal .ant-modal-content {
    min-height: 100% !important;
    border-radius: 0;
  }
}

.ant-input-fake-disabled.ant-input,
.ant-input-fake-disabled > .ant-input {
  cursor: default !important;
  color: rgba(0, 0, 0, 0.55);
  background-color: rgba(0, 0, 0, 0.04);
  border-color: #d9d9d9;
}

.ant-editor-touchpoint-share-modal {
  width: 100% !important;
  max-width: 120rem !important;
  height: 100% !important;
  max-height: 100% !important;
  padding: 4rem;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
  right: 0;
}

.ant-editor-touchpoint-share-modal .ant-modal-content {
  padding: 0 !important;
  width: 100%;
  height: 100%;
  overflow: hidden !important;
  border-radius: 0.8rem;
}

.ant-editor-share-touchpoint-tabs-top > .ant-tabs-nav::before {
  border-bottom: 1px solid #d1d1d1;
}

@media (max-width: 992px) {
  .ant-editor-touchpoint-share-modal {
    padding: 0;
  }

  .ant-editor-touchpoint-share-modal .ant-modal-content {
    border-radius: 0;
  }
}

.select-no-drop-icon .ant-select-arrow {
  display: none;
}

.ant-carousel .journey-snapshot-carousel.slick-slider .slick-dots {
  bottom: -20px;
  height: 16px;
}

.ant-carousel .journey-snapshot-carousel.slick-slider .slick-dots button {
  width: 10px;
  height: 10px;
  background: #d9d9d9;
  border-radius: 100%;
  opacity: 100;
  margin: 3px 0;
}

.ant-carousel .journey-snapshot-carousel.slick-slider .slick-dots .slick-active button {
  width: 16px;
  height: 16px;
  background: #000;
  margin: 0;
}

.intake-form-carousel .ant-carousel {
  height: 100%;
}

.intake-form-carousel .ant-carousel .slick-slider {
  height: 100%;
}

.intake-form-progress .ant-progress-text {
  color: #140165 !important;
  font-weight: 600;
  font-size: 2.4rem;
}

.intake-form-progress.green .ant-progress-text {
  color: #4cc93f !important;
  font-size: 3rem;
}

.slim-table .ant-table {
  font-size: 12px;
}

.slim-table .ant-table-cell {
  padding: 5px !important;
}

.slim-table .ant-table {
  font-size: 12px;
}

.slim-table .ant-table-cell {
  padding: 5px !important;
}
